<template>
  <div class="detador" :style="videostyle">
    <div class="detador-top" @click="handleFullScreen()">
      <TitleBox :titlename="titlename"/>
      <div class="closebox" @click="close()">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="detador-center" id="fullscreen" ref="fullscreen" preload="auto">
      <easy-player ref="videoplay" :video-url="vodeoUrl">
      </easy-player>
    </div>
  </div>
</template>

<script>
import EasyPlayer from '@easydarwin/easyplayer'
import TitleBox from '../visualization/components/title/index.vue'
// import videojs from 'video.js'
import 'videojs-contrib-hls'
import '@videojs/http-streaming'
// import './EasyPlayer-element.min.js'
import 'video.js/dist/video-js.css' // videojs样式

export default {
  props: ['fishOuturl', 'fishOutname'],
  components: {
    TitleBox,
    EasyPlayer

  },
  data () {
    return {
      fullscreen: false,
      isshow: false,
      titlename: '29°57′41.80″N',
      player: null,
      vodeoUrl: '',
      videostyle: {}

    }
  },
  computed: {

  },
  watch: {
    fishOuturl: {
      immediate: true,
      handler (newvalue, lodvalue) {
        // this.$nextTick(() => {
        console.log(newvalue, 'zizujianop')
        if (!newvalue) {
          this.$message.warning('未检测该视频路径')
        }
        this.vodeoUrl = newvalue + '.flv'
      }
    },
    fishOutname: {
      immediate: true,
      handler (newvalue, lodvalue) {
        // this.$nextTick(() => {
        // console.log(newvalue, 'zizujianop')
        this.titlename = newvalue
        // console.log(this.vodeoUrl, 'vodeoUrl')
        // })
      }
    }

  },
  methods: {

    close () {
      this.$emit('close')
    }

  },

  created () {

  },
  mounted () {
  },
  beforeDestroy () {
    // if (this.player != null) {
    //   this.player.dispose() // dispose()会直接删除Dom元素
    // }
  }
  // destroyed() {
  //           this.player.dispose()
  //    },
}
</script>
<style lang="less" scoped>
/deep/ .vjs-icon-placeholder {
  display: none;
}
.detador {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // position: relative;
  background: RGBA(10, 34, 55, 1);
  border: 2px solid #59a5ff;
  // display: flex;
  z-index: 3;

  &-top {
    width: 100%;
    height: 4%;
    margin-left: 1%;
    display: flex;

    .closebox {
      position: absolute;
      right: 0;
      top: 0%;
      width: 30px;
      height: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      text-align: center;
      font-family: SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
  }

  &-center {
    width: 100%;
    height: 96%;
    color: #ffffff;
    position: relative;

    .img1 {
      width: 100%;
      height: 100%;
    }

    .img2 {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 149px;
      right: 55px;
    }

    .imgbox {
      width: 112px;
      height: 300px;
      position: absolute;
      top: 64px;
      right: 15px;
      z-index: 99;

      // position: relative;
      .img3 {
        width: 112px;
        height: 153px;
      }

      .imgjt {
        width: 42px;
        height: 42px;
        margin-top: 10px;
        margin-left: 35px;
      }

      .imglp {
        width: 42px;
        height: 42px;
        margin-top: 15px;
        margin-left: 35px;
      }
      .topbtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz1.png');
        background-size: 100% 100%;
        position: absolute;
        top: 45px;
        right: 42px;
        cursor: pointer;
        z-index: 999;
      }

      .leftbtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz3.png');
        background-size: 100% 100%;
        position: absolute;
        top: 85px;
        left: 5px;
        cursor: pointer;
        z-index: 999;
      }

      .ribtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz4.png');
        background-size: 100% 100%;
        position: absolute;
        top: 85px;
        right: 5px;
        cursor: pointer;
        z-index: 999;
      }

      .bombtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz2.png');
        background-size: 100% 100%;
        position: absolute;
        top: 130px;
        right: 42px;
        cursor: pointer;
        z-index: 999;
      }
      .ltbtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz5.png');
        background-size: 100% 100%;
        position: absolute;
        top: 60px;
        left: 20px;
        cursor: pointer;
        z-index: 999;
      }
      .rtbtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz6.png');
        background-size: 100% 100%;
        position: absolute;
        top: 60px;
        right: 18px;
        cursor: pointer;
        z-index: 999;
      }
      .lbbtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz7.png');
        background-size: 100% 100%;
        position: absolute;
        top: 110px;
        left: 20px;
        cursor: pointer;
        z-index: 999;
      }
      .rbbtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz8.png');
        background-size: 100% 100%;
        position: absolute;
        top: 110px;
        right: 18px;
        cursor: pointer;
        z-index: 999;
      }
      .bigger {
        width: 24px;
        height: 24px;
        // background: red;
        background-size: 100% 100%;
        position: absolute;
        top: 10px;
        left: 15px;
        cursor: pointer;
        z-index: 999;
      }
      .smaller {
        width: 24px;
        height: 24px;
        // background: red;
        background-size: 100% 100%;
        position: absolute;
        top: 10px;
        right: 15px;
        cursor: pointer;
        z-index: 999;
      }
    }
    video {
      object-fit: contain;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    canvas {
      width: 70%;
      height: 110%;
    }
  }
}
</style>
